document.addEventListener('DOMContentLoaded', () => {
    setStateHiddenContent()
    openCurrentSlide();
})

const setStateHiddenContent = () => {
    setClickToTargetElements('js-toggle-trigger')
}

const setClickToTargetElements = (element) => {
    const getActionElement = document.querySelectorAll(`.${element}`)

    getActionElement.forEach((element) => {
        element.addEventListener('click', () => {
            getTargetElements(element)
        })
    })
}

const getTargetElements = (element) => {
    const dataAttributeValue = element.getAttribute('data-toggle')
    const targetContentElement = document.getElementById(`${dataAttributeValue}`)

    if (dataAttributeValue && targetContentElement) {
        toggleClassToTargetContentElement(targetContentElement)
        toggleClassForToggleButton(element)
    }
}

const toggleClassToTargetContentElement = (targetContentElement) => {
    targetContentElement.classList.toggle('active')
}

const toggleClassForToggleButton = (targetButton) => {
    targetButton.classList.toggle('active-button')
}

const openCurrentSlide = () => {
    const hash = window.location.hash;
    if (hash.length > 0) {
        const elementId = hash.substring(1);
        const targetContentElement = document.getElementById(elementId);
        const targetButton = document.querySelector(`[data-toggle="${elementId}"]`);
        if (elementId && targetContentElement) {
            toggleClassToTargetContentElement(targetContentElement)
            toggleClassForToggleButton(targetButton)
        }
        targetContentElement.scrollIntoView(true);
    }
    return false
}